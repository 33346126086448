import React, { useState } from "react";
import { MdOutlineGpsFixed } from "react-icons/md";

export default function LocationCheckbox({
  label,
  initialChecked: initiallyChecked = false,
  onChange,
  name,
}) {
  const defaultChecked = initiallyChecked ? true : false;
  const [isChecked, setIsChecked] = useState(defaultChecked);

  const handleClick = () => {
    if (!isChecked) {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;

        onChange(longitude, latitude, true);
      });
    } else {
      navigator.geolocation.getCurrentPosition((position) => {
        const { latitude, longitude } = position.coords;

        onChange(longitude, latitude, false);
      });
    }
    setIsChecked((prevIsChecked) => !prevIsChecked);
  };

  return (
    <div
      className="flex items-center text-text-color-gray cursor-pointer text-[16px] mlg:text-[20px] xl:text-[22px] xxl:text-[27px] xxxl:text-[35px] pr-[5px] py-[0]  gap-[15px] select-none hover:text-primary-color"
      onClick={handleClick}
    >
      <MdOutlineGpsFixed className="min-w-[20px]" size={22} />
      <span>{label}</span>
      <input
        type="checkbox"
        checked={isChecked}
        readOnly
        className={
          isChecked
            ? 'checked after:content-["_✓"] after:text-[#fff] after:text-[16px] mlg:text-[20px] xl:text-[22px] xxl:text-[27px] xxxl:text-[35px] bg-primary-color appearance-none w-[1.2em] h-[1.2em] min-w-[1.2em] min-h-[1.2em] rounded-[0.25em] outline-none cursor-pointer border-[1px] border-solid border-primary-color flex justify-center items-center hover:border-primary-color '
            : "text-[16px] mlg:text-[20px] xl:text-[22px] xxl:text-[27px] xxxl:text-[35px] hover:border-primary-color appearance-none w-[1.2em] h-[1.2em] min-w-[1.2em] min-h-[1.2em] rounded-[0.25em] outline-none cursor-pointer border-[1px] border-solid border-text-color-gray flex justify-center items-center"
        }
      />
    </div>
  );
}
