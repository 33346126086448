import React, { useState } from "react";
import { useApiData } from "../../data/Api";

export default function CustomCheckbox(props) {
  const { marathons } = useApiData();

  let distanceCount = 0;
  let utilCount = 0;

  if (props.distance === true) {
    for (let i = 0; i < marathons.length; i++) {
      for (let j = 0; j < marathons[i].races.length; j++) {
        if (
          marathons[i].races[j].distance >= props.min &&
          marathons[i].races[j].distance <= props.max
        ) {
          distanceCount++;
          break;
        }
      }
    }
  } else if (props.distance === false) {
    for (let i = 0; i < marathons.length; i++) {
      for (let j = 0; j < marathons[i].races.length; j++) {
        if (marathons[i].races[j].utilities.includes(props.value)) {
          utilCount++;
          break;
        }
      }
    }
  }
  const defaultChecked = props.initiallyChecked ? true : false;
  const [isChecked, setIsChecked] = useState(defaultChecked);
  const handleClick = () => {
    setIsChecked((prevIsChecked) => !prevIsChecked);
    props.onChange(props.name, props.value);
  };

  return (
    <div
      className="flex items-center text-text-color-gray cursor-pointer text-[16px] mlg:text-[20px] xl:text-[22px] xxl:text-[27px] xxxl:text-[35px] px-[5px] py-[10px] hover:text-primary-color"
      onClick={handleClick}
    >
      <input
        type="checkbox"
        checked={isChecked}
        readOnly
        className={
          isChecked
            ? 'bg-primary-color border-primary-color after:content-["_✓"] after:text-[#fff] after:text-[16px] mlg:text-[20px] xl:text-[22px] xxl:text-[27px] xxxl:text-[35px] checked min-w-[1.2em] min-h-[1.2em] w-[1.2em] h-[1.2em] rounded-[0.25em] mr-[0.8em] appearance-none outline-none cursor-pointer flex items-center justify-center'
            : "text-[16px] mlg:text-[20px] xl:text-[22px] xxl:text-[27px] xxxl:text-[35px] min-w-[1.2em] min-h-[1.2em] w-[1.2em] h-[1.2em] rounded-[0.25em] mr-[0.8em] appearance-none outline-none cursor-pointer flex items-center justify-center border-[1px] border-solid border-text-color-gray"
        }
      />
      <span className="leading-[130%]">
        {props.label}{" "}
        {props.distance === true ? `(${distanceCount})` : `(${utilCount})`}
      </span>
    </div>
  );
}
