import React, { useState } from "react";
import { SlCalender } from "react-icons/sl";
import { IoLocationSharp } from "react-icons/io5";
import { FaCheck } from "react-icons/fa";
import { FaUsers } from "react-icons/fa";
import { IoMdShare } from "react-icons/io";
import { FaUserAlt } from "react-icons/fa";
import { useLocation } from "react-router";
import water from "../../assets/water.png";
import shirt from "../../assets/shirt.png";
import medal from "../../assets/medal.png";
import number from "../../assets/number.png";
import { IoIosLink } from "react-icons/io";

export default function RacesIfnormationPage() {
  const [selected, setSelected] = useState(0);
  const [copy, setCopy] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  function handlePopUp() {
    setShowSuccessPopup(true);
    setTimeout(() => {
      setShowSuccessPopup(false);
    }, 4500);
  }

  let waterPass = true;
  let medalPass = true;
  let numberPass = true;
  let shirtPass = true;

  const location = useLocation();

  const event = location.state.data;

  const allraces = [];

  const mainU = [];
  const secU = [];

  function handleCopy() {
    setCopy(window.location.href);
    navigator.clipboard.writeText(copy);
  }

  for (let i = 0; i < event.races.length; i++) {
    const race_date = event.races[i].start_date.split('/');
    const start_date = `${race_date[2]}/${race_date[1]}/${race_date[0]}`;
    allraces.push(
      <button
        onClick={() => {
          setSelected(i);
        }}
        id={i}
        className={
          selected === i
            ? "px-6 py-1 rounded-[9px] text-white bg-primary-color"
            : "bg-white px-6 py-1 rounded-[9px]"
        }
      >
        {event.races[i].distance.toFixed(2)} Χλμ ({start_date})
      </button>
    );
  }

  const waterRegex = /^(νερ((ό|ο)|(ά|α)))$/i;
  const medalRegex = /.*((μετ(ά|α)λλιο)|(μετ(ά|α)λλια)).*/i;
  const partNumRegex = /.*((αριθμ(ό|ο)ς?) (συμμετοχ(ή|η))).*/i;
  const shirtRegex = /.*((μπλο(ύ|υ)ζ(ά|α)(κι)?)|(t-shirt)).*/i;

  for (let i = 0; i < event.races.length; i++) {
    mainU[i] = [];
    let temp = [];

    if (event.races[i].utilities.some((utility) => waterRegex.test(utility))) {
      temp.push(
        <div className="text-center">
          <img
            src={water}
            className="w-[60px] h-[60px] md:w-[100px] md:h-[100px]"
          />
          Νερό
        </div>
      );
    }

    if (event.races[i].utilities.some((utility) => medalRegex.test(utility))) {
      temp.push(
        <div className="text-center">
          <img
            src={medal}
            className="w-[60px] h-[60px] md:w-[100px] md:h-[100px]"
          />{" "}
          Μετάλλιο
        </div>
      );
    }

    if (
      event.races[i].utilities.some((utility) => partNumRegex.test(utility))
    ) {
      temp.push(
        <div className="text-center flex flex-col items-center">
          <img
            src={number}
            className="w-[60px] h-[60px] md:w-[100px] md:h-[100px]"
          />{" "}
          Αριθμός Συμμετοχής
        </div>
      );
    }
    if (event.races[i].utilities.some((utility) => shirtRegex.test(utility))) {
      temp.push(
        <div className="text-center">
          <img
            src={shirt}
            className="w-[60px] h-[60px] md:w-[100px] md:h-[100px]"
          />{" "}
          Μπλούζα
        </div>
      );
    }

    mainU[i].push(temp);
  }

  for (let i = 0; i < event.races.length; i++) {
    let temp = [];
    secU[i] = [];
    for (let j = 0; j < event.races[i].utilities.length; j++) {
      temp.push(
        <div className="flex items-start gap-2 md:gap-3">
          <FaCheck className="checkUtilInfo" size={22} />
          <div className="flex flex-wrap w-full md:max-w-[300px]">
            {event.races[i].utilities[j]}
          </div>
        </div>
      );
    }
    secU[i].push(temp);
  }

  if (event.races[selected].participant_types.includes("kids")) {
    secU[selected].push(
      <div className="flex items-center gap-3">
        <FaCheck className="checkUtilInfo" size={22} />
        Για παιδιά
      </div>
    );
  }
  if (event.races[selected].participant_types.includes("special needs")) {
    secU[selected].push(
      <div className="flex items-center gap-3">
        <FaCheck className="checkUtilInfo" size={22} />
        ΑΜΕΑ
      </div>
    );
  }

  let date = event.date.split("/");
  let event_date = `${date[2]}/${date[1]}/${date[0]}`;

  const races = [];
  const dis = [false, false, false, false, false, false];

  for (let i = 0; i < event.races.length; i++) {
    if (event.races[i].distance < 5 && dis[0] === false) {
      dis[0] = true;
      races[0] = (
        <p
          className={`text-[14px] md:text-[16px] mlg:text-[18px] xl:text-[20px] xxl:text-[25px] xxxl:text-[35px] bg-[#8d30ff] px-2 font-semibold flex justify-center items-center w-fit py-1 text-white rounded-[30px]`}
        >
          0-5km
        </p>
      );
    } else if (
      event.races[i].distance >= 5 &&
      event.races[i].distance <= 10 &&
      dis[1] === false
    ) {
      dis[1] = true;
      races[1] = (
        <p
          className={`text-[14px] md:text-[16px] mlg:text-[18px] xl:text-[20px] xxl:text-[25px] xxxl:text-[35px] bg-[#22a736] px-2 font-semibold flex justify-center items-center w-fit py-1 text-white rounded-[30px]`}
        >
          5-10km
        </p>
      );
    } else if (
      event.races[i].distance > 10 &&
      event.races[i].distance <= 20 &&
      dis[2] === false
    ) {
      dis[2] = true;
      races[2] = (
        <p
          className={`text-[14px] md:text-[16px] mlg:text-[18px] xl:text-[20px] xxl:text-[25px] xxxl:text-[35px] bg-[#acdb0f] px-2 font-semibold flex justify-center items-center w-fit py-1 text-white rounded-[30px]`}
        >
          10-20km
        </p>
      );
    } else if (
      event.races[i].distance > 20 &&
      event.races[i].distance <= 30 &&
      dis[3] === false
    ) {
      dis[3] = true;
      races[3] = (
        <p
          className={`text-[14px] md:text-[16px] mlg:text-[18px] xl:text-[20px] xxl:text-[25px] xxxl:text-[35px] bg-[#eb8911] px-2 font-semibold flex justify-center items-center w-fit py-1 text-white rounded-[30px]`}
        >
          20-30km
        </p>
      );
    } else if (
      event.races[i].distance > 30 &&
      event.races[i].distance <= 41 &&
      dis[4] === false
    ) {
      dis[4] = true;
      races[4] = (
        <p
          className={`text-[14px] md:text-[16px] mlg:text-[18px] xl:text-[20px] xxl:text-[25px] xxxl:text-[35px] bg-[#da1212] px-2 font-semibold flex justify-center items-center w-fit py-1 text-white rounded-[30px]`}
        >
          30-41km
        </p>
      );
    } else if (event.races[i].distance > 41 && dis[5] === false) {
      dis[5] = true;
      races[5] = (
        <p
          className={`text-[14px] md:text-[16px] mlg:text-[18px] xl:text-[20px] xxl:text-[25px] xxxl:text-[35px] bg-[#1328e8] px-2 font-semibold flex justify-center items-center w-fit py-1 text-white rounded-[30px]`}
        >
          M
        </p>
      );
    }
  }

  return (
    <article className="relative raceinfromation">
      <section className="ml:flex justify-between p-8 item-center">
        <div className="w-full ml:w-[50%] flex flex-col ml:h-fit h-[300px] gap-5 items-center ml:mb-0 mb-10">
          <h1 className="text-[20px] md:text-[27px] ml:text-[32px] lg:text-[37px] mlg:text-[42px] xl:text-[45px] xxl:text-[55px] xxxl:text-[60px] text-primary-darker-color text-center font-bold">
            {event.event_name}
          </h1>
          <div className="flex gap-5">{races}</div>
          <div className="flex flex-col gap-4 w-[85%] ml:w-[70%]">
            <div className="md:flex space-y-4 justify-between w-full">
              <div className="text-[14px] md:text-[16px] lg:text-[17px] mlg:text-[19px] xl:text-[23px] xxl:text-[28px] xxxl:text-[38px] flex gap-2 justify-start items-center text-[#7A7A7A] md:w-1/2">
                <div>
                  <IoLocationSharp size={25} />
                </div>
                {event.location}
              </div>
              <div className="text-[14px] md:text-[16px] lg:text-[17px] mlg:text-[19px] xl:text-[23px] xxl:text-[28px] xxxl:text-[38px] flex  gap-2 md:w-1/2 text-[#7A7A7A] md:justify-center items-center">
                <div>
                  <SlCalender size={25} />
                </div>
                {event_date}
              </div>
            </div>
            <div className="md:flex justify-between w-full">
              <div className="text-[14px] md:text-[16px] lg:text-[17px] mlg:text-[19px] xl:text-[23px] xxl:text-[28px] xxxl:text-[38px] hidden md:flex gap-2 justify-start items-center text-[#7A7A7A] w-1/2">
                <div>
                  <IoMdShare size={25} />
                </div>
                Κοινοποίηση:
              </div>
              <div
                id="copy"
                onClick={() => {
                  handleCopy();
                  handlePopUp();
                }}
                className="text-[14px] md:text-[16px] lg:text-[17px] mlg:text-[19px] xl:text-[23px] xxl:text-[28px] xxxl:text-[38px] hover:underline hover:cursor-pointer md:w-1/2 text-[#7A7A7A] md:justify-center items-center flex gap-2 text-center"
              >
                <div>
                  <IoIosLink size={25} />
                </div>
                Αντιγραφή Συνδέσμου
              </div>
            </div>
            <div className="flex flex-wrap items-center md:justify-between w-full">
              <div className="text-[14px] md:text-[16px] lg:text-[17px] mlg:text-[19px] xl:text-[23px] xxl:text-[28px] xxxl:text-[38px] flex gap-2 justify-start items-center text-[#7A7A7A] md:w-1/2">
                <div>
                  <FaUsers size={25} />
                </div>
                Διοργανωτές:
              </div>
              <div className="text-[14px] md:text-[16px] lg:text-[17px] mlg:text-[19px] xl:text-[23px] xxl:text-[28px] xxxl:text-[38px] md:w-1/2 text-[#7A7A7A] md:text-center">
                {event.organizers.join(", ")}
              </div>
            </div>
          </div>
        </div>
        <div className="w-[100%] ml:w-[50%] flex flex-col gap-5 justify-center items-center">
          <img
            className="max-h-[300px] mlg:w-[60%] rounded-[9px] shadow-[4.0px_8.0px_8.0px_rgba(0,0,0,0.38)]"
            src={event.image}
          />
          <div className="text-[14px] md:text-[16px] lg:text-[17px] mlg:text-[19px] xl:text-[23px] xxl:text-[28px] xxxl:text-[38px] h-[100px] overflow-auto text-center text-primary-darker-color">
            {event.description}
          </div>
        </div>
      </section>
      <section className="bg-[#1235B1] pb-2 rounded-[16px]">
        <h2 className="text-[16px] md:text-[18px] lg:text-[20px] mlg:text-[24px] xl:text-[27px] xxl:text-[32px] xxxl:text-[42px] pl-11 pt-11 text-[#8BACF6]">
          Παροχές
        </h2>
        <div>
          <div className="text-[14px] md:text-[16px] lg:text-[17px] mlg:text-[19px] xl:text-[23px] xxl:text-[28px] xxxl:text-[38px] flex justify-center items-center gap-7 text-white p-4 md:p-0">
            {mainU[selected]}
          </div>
          <div className="overflow-auto  text-[16px] lg:text-[17px] mlg:text-[19px] xl:text-[23px] xxl:text-[28px] xxxl:text-[38px] pl-11 text-white flex-wrap  md:flex gap-11 mt-8">
            <h3 className="md:mb-0 mb-4">Επιπλέον: </h3>
            <div className="md:flex md:flex-col gap-5 space-y-3  text-start h-[200px] mlg:h-[230px] flex-wrap md:w-full w-[70%]">
              {secU[selected]}
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 pb-2">
        <div className="text-[14px] md:text-[16px] lg:text-[17px] mlg:text-[19px] xl:text-[23px] xxl:text-[28px] xxxl:text-[38px] p-5 flex gap-2 md:gap-5 justify-center items-center">
          {allraces}
        </div>
        <div className="w-[90%] ml:w-[80%] m-auto">
          <iframe
            className="w-full px-6 md:px-24 h-[300px] md:h-[500px]"
            src={`https://www.google.com/maps/embed/v1/place?q=${encodeURIComponent(
              event.location
            )}&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8`}
          ></iframe>
        </div>
      </section>
      {showSuccessPopup && (
        <div className="text-[14px] lg:text-[18px] mlg:text-[20px] xl:text-[22px] xxl:text-[24px] xxxl:text-[28px] absolute top-[20px] w-full animate-bounce right-3 ease-in-out duration-900 ml:flex hidden items-center justify-end">
          <div className="bg-green-500 font-bold text-white py-4 px-8 rounded-md">
            Έγινε αντιγραφή!
          </div>
        </div>
      )}

      {showSuccessPopup && (
        <div className="text-[14px] lg:text-[18px] mlg:text-[20px] xl:text-[22px] xxl:text-[24px] xxxl:text-[28px] fixed bottom-2 w-full animate-bounce ease-in-out duration-900 flex ml:hidden items-center justify-center">
          <div className="bg-green-500 font-bold text-white py-4 px-8 rounded-md">
            Έγινε αντιγραφή!
          </div>
        </div>
      )}
    </article>
  );
}
