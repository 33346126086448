import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import img from "../../assets/Logo.png";
import img2 from "../../assets/navBarMobile.png";
import { BiSearchAlt } from "react-icons/bi";
import { useApiData } from "../../data/Api";
import { useLocation } from "react-router-dom";

export default function Navbar(props) {
  const { marathons } = useApiData();

  const navigation = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;

  // Nav Input
  const [query, setQuery] = useState("");

  // Hides the Search results that apears under the serach bar
  const [hideSerachResults, setHideSerachResults] = useState(false);

  let numOfRaces = 0;

  // Brings the viwer to the top of the page
  function ToTOp() {
    window.scrollTo(0, 0);
  }

  // It clears the search on command
  useEffect(() => {
    setQuery("");
    same("");
    if (String(pathname) === "/races") {
      navigation("/races", {
        state: {
          search: document.getElementById("searchBar").value,
          shouldSearch: true,
        },
      });
    }
  }, [props.clear]);

  function toggleSearchResults(flag) {
    setHideSerachResults(flag);
  }

  // When the user click on a search result it keeps the query and the nav bars (Mobile and computer) the same
  function handleSearchRes(aString) {
    setQuery(aString);
    document.getElementById("searchBar").value = aString;
    document.getElementById("mobileSearchBar").value = aString;
  }

  // Its called when you want to keep the 2 searchbars the same
  function same(aString) {
    document.getElementById("searchBar").value = aString;
    document.getElementById("mobileSearchBar").value = aString;
  }

  // Creates the search results tha you can unde the search bar when you type
  const results = marathons.map((mar) => {
    if (
      mar.event_name.toUpperCase().includes(query.toUpperCase()) ||
      mar.location.toUpperCase().includes(query.toUpperCase())
    ) {
      numOfRaces++;
      return (
        <div
          key={mar.event_name}
          className="hover:cursor-pointer pb-3 space-y-1 relative "
        >
          <Link
            to={"/races"}
            state={{ search: mar.event_name, shouldSearch: true }}
            onClick={() => handleSearchRes(mar.event_name)}
          >
            <div className="text-[15px]  mlg:text-[21px] xl:text-[25px] xxl:text-[30px] xxxl:text-[40px] hover:bg-light-primary-color hover:text-white p-[10px] w-[95%] duration-200 font-semibold rounded-[10px] ease-in-out">
              {mar.event_name}
            </div>
            <hr className="hover:bg-primary-darker-color w-[95%] bg-primary-color" />
          </Link>
        </div>
      );
    }
  });

  // Key listener for Enter
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        // Navigate to races
        setHideSerachResults(true);
        navigation("/races", {
          state: {
            search: document.getElementById("searchBar").value,
            shouldSearch: true,
          },
        });
      }
    };

    // Attach the event listener
    window.addEventListener("keypress", handleKeyPress);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, [navigation]);

  return (
    <nav className="sticky top-[0] z-10">
      <section className="hidden ml:flex justify-between items-center w-full h-fit px-[32px] py-[7px] bg-white shadow-lg ">
        <Link
          className="md:w-[170px] lg:w-[200px] mlg:w-[250px] xl:w-[300px] xxl:w-[350px] xxxl:w-[400px]"
          to="/"
        >
          <img
            className="md:min-w-[170px] lg:w-[200px] mlg:w-[250px] xl:w-[300px] xxl:w-[350px] xxxl:w-[400px]"
            src={img}
            alt="logo"
          />
        </Link>

        <div className="flex flex-col w-[100%] justify-center items-center relative">
          <div className="justify-center w-[100%] flex">
            <input
              id="searchBar"
              className={
                hideSerachResults || query === ""
                  ? "focus-visible:outline-none rounded-l-[30px] z-10 border-solid border-[2px] border-primary-color text-[15px]  mlg:text-[21px] xl:text-[25px] xxl:text-[30px] xxxl:text-[40px] w-[50%] py-[8px] px-[20px]"
                  : "focus-visible:outline-none rounded-tl-[20px] z-10 border-solid border-[2px] rounded-b-none border-primary-color text-[15px]  mlg:text-[21px] xl:text-[25px] xxl:text-[30px] xxxl:text-[40px] w-[50%] py-[8px] px-[20px]"
              }
              type="search"
              placeholder="Αναζήτηση αγώνα ή τοποθεσίας"
              onChange={(e) => {
                setQuery(e.target.value);
                toggleSearchResults(false);
                same(e.target.value);
              }}
              onFocus={() => toggleSearchResults(false)}
              onBlur={() => {
                // waits a bit and then hides the search results
                setTimeout(() => toggleSearchResults(true), 500);
              }}
              autoComplete="off"
            />
            <Link
              state={{ search: query, shouldSearch: true }}
              to="/races"
              className="lg:w-[18%] w-[25%]  z-10"
              id="searchbutton"
            >
              <button
                className={
                  hideSerachResults || query === ""
                    ? "rounded-r-[30px]  w-[100%] z-10 text-center text-[15px]  mlg:text-[21px] xl:text-[25px] xxl:text-[30px] xxxl:text-[40px] bg-primary-color  text-white px-[40px] py-[8px] border-primary-color border-solid border-[2px]"
                    : "rounded-tr-[20px] z-10 text-[15px]  mlg:text-[21px] xl:text-[25px] xxl:text-[30px] xxxl:text-[40px] bg-primary-color text-center text-white px-[40px] py-[8px] border-primary-color border-solid border-[2px] w-[100%] rounded-b-none"
                }
                onClick={() => {
                  handleSearchRes(query);
                  toggleSearchResults(true);
                  ToTOp();
                }}
              >
                Αναζήτηση
              </button>
            </Link>
          </div>

          <div
            className={
              hideSerachResults || query === ""
                ? `hidden`
                : `text-[15px]  mlg:text-[21px] xl:text-[25px] xxl:text-[30px] xxxl:text-[40px] rounded-b-[20px] max-h-[250px] overflow-y-auto py-[20px] top-10 lg:w-[68%] w-[75%] absolute px-[20px] border-t-0 border-primary-color border-solid border-[2px] bg-bg-color`
            }
          >
            {numOfRaces > 0 ? results : "Δεν βρέθηκαν αποτελέσματα!"}
          </div>
        </div>

        <div className="flex items-center p-[0] space-x-[20px]">
          <span>
            <button className="hover:text-primary-color text-text-color-gray text-[18px]  mlg:text-[21px] xl:text-[25px] xxl:text-[30px] xxxl:text-[40px] nav_link">
              <Link className="home-link" to="/">
                Aρχική
              </Link>
            </button>
          </span>
        </div>
      </section>

      {/* This is the mobile version of the navbar */}
      <section className="ml:hidden w-full  flex flex-col space-y-[5px] h-fit shadow-lg px-[16px] py-[10px] bg-bg-color">
        <div className="px-[10px] py-[6px] ">
          <Link className="w-[100px]" to="/">
            <img className="w-[100px]" src={img} alt="logo" />
          </Link>
        </div>

        <div className=" text-center w-full relative flex items-center">
          <div className="flex w-full">
            <input
              id="mobileSearchBar"
              className={
                hideSerachResults || query === ""
                  ? "focus-visible:outline-none z-10 rounded-[30px] border-solid border-[1px] border-primary-color rounded-r-none border-r-0  text-Normal-big-text-size  w-[90%] py-[8px] px-[20px]"
                  : "focus-visible:outline-none z-10 rounded-b-none rounded-[20px] border-solid border-[1px] border-primary-color rounded-r-none border-r-0  text-Normal-big-text-size  w-[90%] py-[8px] px-[20px]"
              }
              type="search"
              placeholder="Αναζήτηση αγώνα ή τοποθεσίας"
              onChange={(e) => {
                setQuery(e.target.value);
                toggleSearchResults(false);
                same(e.target.value);
              }}
              onFocus={() => toggleSearchResults(false)}
              onBlur={() => {
                setTimeout(() => toggleSearchResults(true), 500);
              }}
              autoComplete="off"
            />

            <Link
              state={{ search: query, shouldSearch: true }}
              to="/races"
              className={
                hideSerachResults || query === ""
                  ? " text-primary-color bg-white rounded-[30px] z-10 border-solid border-[1px] flex justify-center border-primary-color w-[10%] rounded-l-none border-l-0 py-[8px]"
                  : "w-[10%] text-primary-color rounded-[20px] border-primary-color z-10 border-solid border-[1px] pr-4 rounded-b-none  rounded-l-none border-l-0 bg-white flex justify-center py-[8px]"
              }
              onClick={() => {
                handleSearchRes(query);
                toggleSearchResults(true);
                ToTOp();
              }}
            >
              <BiSearchAlt size={"24px"} />
            </Link>
          </div>

          <div
            className={
              hideSerachResults || query === ""
                ? "hidden"
                : "max-h-[200px] overflow-y-auto  absolute top-10 w-[100%] px-[20px] bg-bg-color text-left border-[1px] border-primary-color border-solid rounded-[20px] border-t-0 rounded-t-none py-[20px]"
            }
          >
            {numOfRaces > 0 ? results : "Δεν βρέθηκαν αποτελέσματα!"}
          </div>
        </div>
      </section>
    </nav>
  );
}
