import React from "react";
import { BsGithub } from "react-icons/bs";
import { AiFillLinkedin } from "react-icons/ai";

// This component displays the data from the folder data/Team
function Member(props) {
  return (
    <div>
      <div className="shadow-[0_0px_10px_1px_rgba(159,159,159,0.25)]  rounded-[10px] py-3 bg-white w-[200px]  flex flex-col items-center justify-center gap-2">
        <img
          src={props.img}
          className="w-[80px] border-solid border-[2px] border-primary-color rounded-full"
        ></img>
        <div className="text-Normal-big-text-size text-center text-primary-darker-color ">
          <h1>{props.name}</h1>
          <h1>{props.lastName}</h1>
        </div>
        <h2 className="text-primary-darker-color text-[15px] font-bold">
          {props.position}
        </h2>
        <ul className="flex gap-6 justify-center items-center">
          <li className={props.github === ""?"hidden":"hover:cursor-pointer"}>
            <a
              target="_blank"
              href={props.github}
              className="text-primary-color "
            >
              <BsGithub size={28} />
            </a>
          </li>
          <li className="hover:cursor-pointer">
            <a
              target="_blank"
              href={props.linkedin}
              className="text-primary-color"
            >
              <AiFillLinkedin size={28} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Member;
