import React, { useState } from "react";
import CustomCheckbox from "../../../../components/CustomCheckbox";
import { useApiData } from "../../../../data/Api";

// Rename το function και όλες τις σχετικές μεταβλητές σε "AmenitiesFilters"
export default function AmenitiesFilters(props) {
  const { setUrl, url } = useApiData();

  //Race amenities
  const [raceAmenities, setRaceAmenities] = useState({
    medals: false,
    shirt: false,
    counter: false,
    water: false,
  });

  const Handling = (name, value) => {
    const checked = !raceAmenities[name];
    setRaceAmenities((raceAmenities) => ({
      ...raceAmenities,
      [name]: checked,
    }));
    if (checked === true) {
      if (url === "/events") {
        setUrl(`/events/filter?&utility=${value}`);
      } else {
        setUrl((url) => url + `&utility=${value}`);
      }
    } else if (checked === false) {
      let updated_url = url.replace(`&utility=${value}`, "");
      setUrl(updated_url);
    }
  };

  return (
    <div>
      <form className="race-amenities-form">
        <p className="text-black ml-[5px] m-[15px] text-[20px] mlg:text-[24px] xl:text-[26px] xxl:text-[31px] xxxl:text-[41px]">
          Παροχές Αγώνα
        </p>
        <div className="mb-[5px] text-text-color-gray">
          <CustomCheckbox
            label="Νερό"
            value="νερό"
            distance={false}
            initiallyChecked={raceAmenities.water}
            onChange={Handling}
            name="water"
          />
          <CustomCheckbox
            label="Μετάλλιο"
            value="μετάλλιο"
            distance={false}
            initiallyChecked={raceAmenities.medals}
            onChange={Handling}
            name="medals"
          />
          <CustomCheckbox
            label="Μπλούζα"
            value="μπλουζάκι"
            distance={false}
            initiallyChecked={raceAmenities.shirt}
            onChange={Handling}
            name="shirt"
          />
          <CustomCheckbox
            label="Αριθμός Συμμετοχής"
            value="αριθμό συμμετοχής"
            distance={false}
            initiallyChecked={raceAmenities.counter}
            onChange={Handling}
            name="counter"
          />
        </div>
      </form>
    </div>
  );
}
