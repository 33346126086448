import Image1 from "../../assets/Konstandin_Kote.jpeg";
import Image2 from "../../assets/Stergioulas_Panagiotis_About_Us.jpg";
import Image3 from "../../assets/Kontaxis_Giannis_About_Us.jpg.png";
import Image4 from "../../assets/tolis_halis.jpg";
import Image5 from "../../assets/Eleni.png";
import Image6 from "../../assets/Nikolaidis_Ioannis.jpg";

export default [
  {
    id: 0,
    name: "Ιωάννης",
    lastName: "Νικολαϊδης",
    img: Image6,
    position: "Εμπνευστής",
    isProf: true,
    github: "",
    linkedin: "https://www.linkedin.com/in/ioannis-nikolaidis-07733a221/",
  },
  {
    id: 1,
    name: "Παναγιώτης",
    lastName: "Στεργιούλας-Μπόλης",
    img: Image2,
    position: "Lead Front-End Developer",
    isFront: false,
    github: "https://github.com/Panos-Stergioulas-Mpolis",
    linkedin: "https://www.linkedin.com/in/panagiotis-stergioulas-bolis/",
    Lead: true,
  },
  {
    id: 2,
    name: "Kostandin",
    lastName: "Kote",
    img: Image1,
    position: "Lead Back-End Developer",
    isFront: false,
    github: "https://github.com/setokk",
    linkedin: "https://www.linkedin.com/in/kkote-sj10/",
    Lead: true,
  },
  {
    id: 3,
    name: "Ελένη",
    lastName: "Σενεπρέμτε",
    img: Image5,
    position: "UI/UX Developer/Designer",
    isFront: true,
    github: "https://github.com/lnesh",
    linkedin: "https://gr.linkedin.com/in/eleni-shenepremte-5b23a8223",
  },
  {
    id: 4,
    name: "Ιωάννης",
    lastName: "Κονταξής",
    img: Image3,
    position: "Front-End Developer",
    isFront: true,
    github: "https://github.com/gianniskontaxis",
    linkedin: "https://www.linkedin.com/in/ioannis-kontaxis-197914225/",
  },
  {
    id: 5,
    name: "Αποστόλης",
    lastName: "Χάλης",
    img: Image4,
    position: "Front-End Developer",
    isFront: true,
    github: "https://github.com/TolisSth",
    linkedin:
      "https://www.linkedin.com/in/tolish?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app",
  },
];
