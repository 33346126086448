import React, { useState } from "react";
import CustomCheckbox from "../../../../components/CustomCheckbox";
import { useApiData } from "../../../../data/Api";

// Rename το function και όλες τις σχετικές μεταβλητές σε "DistanceFilters"
export default function DistanceFilters(props) {
  const { setUrl, url } = useApiData();

  //Race distances
  const [raceDistanceData, setRaceDistanceData] = useState({
    D5: false,
    D10: false,
    D20: false,
    D30: false,
    D40: false,
    fullMar: false,
  });

  const Handling = (name, value) => {
    const checked = !raceDistanceData[name];
    setRaceDistanceData((raceDistanceData) => ({
      ...raceDistanceData,
      [name]: checked,
    }));
    if (checked === true) {
      if (url === "/events") {
        setUrl(`/events/filter?&distance=${value}`);
      } else {
        setUrl((url) => url + `&distance=${value}`);
      }
    } else if (checked === false) {
      let updated_url = url.replace(`&distance=${value}`, "");
      setUrl(updated_url);
    }
  };

  return (
    <div>
      <form className="race-distance-form">
        <p className="text-black ml-[5px] m-[15px] text-[20px] mlg:text-[24px] xl:text-[26px]  xxl:text-[31px] xxxl:text-[41px]">
          Μήκος Διαδρομής
        </p>
        <div className="mb-[15px] text-text-color-gray">
          <CustomCheckbox
            label="0-5km"
            value="0.1-5"
            min={0.1}
            max={5}
            distance={true}
            initiallyChecked={raceDistanceData.D5}
            onChange={Handling}
            name="D5"
          />
          <CustomCheckbox
            label="5-10km"
            value="5.1-10"
            min={5.1}
            max={10}
            distance={true}
            initiallyChecked={raceDistanceData.D10}
            onChange={Handling}
            name="D10"
          />
          <CustomCheckbox
            label="10-20km"
            value="10.1-20"
            min={10.1}
            max={20}
            distance={true}
            initiallyChecked={raceDistanceData.D20}
            onChange={Handling}
            name="D20"
          />
          <CustomCheckbox
            label="20-30km"
            value="20.1-30"
            min={20.1}
            max={30}
            distance={true}
            initiallyChecked={raceDistanceData.D30}
            onChange={Handling}
            name="D30"
          />
          <CustomCheckbox
            label="30-40km"
            value="30.1-40"
            min={30.1}
            max={40}
            distance={true}
            initiallyChecked={raceDistanceData.D40}
            onChange={Handling}
            name="D40"
          />
          <CustomCheckbox
            label="Μαραθώνιος"
            value="40.1-100"
            min={40.1}
            max={999999999}
            distance={true}
            initiallyChecked={raceDistanceData.fullMar}
            onChange={Handling}
            name="fullMar"
          />
        </div>
      </form>
    </div>
  );
}
