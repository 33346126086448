import React, { useState } from "react";
import { SlCalender } from "react-icons/sl";
import { ImLocation } from "react-icons/im";
import { Link } from "react-router-dom";

// Rename το function και όλες τις σχετικές μεταβλητές και classNames σε "RaceCard"
export default function Marathons(props) {
  const races = [];
  const dis = [false, false, false, false, false, false];

  for (let i = 0; i < props.races.length; i++) {
    if (props.races[i].distance <= 5 && dis[0] === false) {
      dis[0] = true;
      races[0] = (
        <p
          key={i}
          className={`text-[16px] mlg:text-[18px] xl:text-[20px] xxl:text-[25px] xxxl:text-[35px] bg-[#8d30ff] px-2 font-semibold flex justify-center items-center w-fit py-1 text-white rounded-[30px]`}
        >
          0-5km
        </p>
      );
    } else if (
      props.races[i].distance > 5 &&
      props.races[i].distance <= 10 &&
      dis[1] === false
    ) {
      dis[1] = true;
      races[1] = (
        <p
          key={i}
          className={`text-[16px] mlg:text-[18px] xl:text-[20px] xxl:text-[25px] xxxl:text-[35px] bg-[#22a736] px-2 font-semibold flex justify-center items-center w-fit py-1 text-white rounded-[30px]`}
        >
          5-10km
        </p>
      );
    } else if (
      props.races[i].distance > 10 &&
      props.races[i].distance <= 20 &&
      dis[2] === false
    ) {
      dis[2] = true;
      races[2] = (
        <p
          key={i}
          className={`text-[16px] mlg:text-[18px] xl:text-[20px] xxl:text-[25px] xxxl:text-[35px] bg-[#acdb0f] px-2 font-semibold flex justify-center items-center w-fit py-1 text-white rounded-[30px]`}
        >
          10-20km
        </p>
      );
    } else if (
      props.races[i].distance > 20 &&
      props.races[i].distance <= 30 &&
      dis[3] === false
    ) {
      dis[3] = true;
      races[3] = (
        <p
          key={i}
          className={`text-[16px] mlg:text-[18px] xl:text-[20px] xxl:text-[25px] xxxl:text-[35px] bg-[#eb8911] px-2 font-semibold flex justify-center items-center w-fit py-1 text-white rounded-[30px]`}
        >
          20-30km
        </p>
      );
    } else if (
      props.races[i].distance > 30 &&
      props.races[i].distance <= 41 &&
      dis[4] === false
    ) {
      dis[4] = true;
      races[4] = (
        <p
          key={i}
          className={`text-[16px] mlg:text-[18px] xl:text-[20px] xxl:text-[25px] xxxl:text-[35px] bg-[#da1212] px-2 font-semibold flex justify-center items-center w-fit py-1 text-white rounded-[30px]`}
        >
          30-41km
        </p>
      );
    } else if (props.races[i].distance > 41 && dis[5] === false) {
      dis[5] = true;
      races[5] = (
        <p
          key={i}
          className={`text-[16px] mlg:text-[18px] xl:text-[20px] xxl:text-[25px] xxxl:text-[35px] bg-[#1328e8] px-2 font-semibold flex justify-center items-center w-fit py-1 text-white rounded-[30px]`}
        >
          M
        </p>
      );
    }
  }

  let date = props.date.split("/");
  let event_date = `${date[2]}/${date[1]}/${date[0]}`;

  return (
    <Link
      className={
        props.homepage !== true
          ? "ml:w-[70%] w-[90%] ms:w-[70%] overflow-hidden flex mb-[40px] bg-white shadow-[rgba(0,_0,_0,_0.24)_0px_3px_8px] mx-auto lg:mx-0 rounded-[15px] relative hover:cursor-pointer  md:h-[180px] h-[150px] mlg:h-[220px] xxl:h-[270px] xxxl:h-[300px]"
          : "ml:w-[70%] w-[90%] ms:w-[70%] overflow-hidden flex mb-[40px] bg-white shadow-[0_0px_10px_1px_rgba(159,159,159,0.25)] mx-auto  rounded-[15px] relative hover:cursor-pointer  md:h-[180px] h-[160px] mlg:h-[220px] xxl:h-[270px] xxxl:h-[300px]"
      }
      title={props.name}
      to={`/races/${props.event_name}`}
      state={{ data: props }}
    >
      <div className="h-full w-[45%] sm:w-[30%] relative">
        <img
          src={props.image}
          alt="race event"
          className=" w-full h-full object-cover"
        ></img>
      </div>
      <div className="flex-col pl-3 py-2 md:justify-between gap-2 flex w-[60%]">
        <h1 className="text-[18px] mlg:text-[21px] xl:text-[24px] xxl:text-[29px] xxxl:text-[39px] max-w-full overflow-x-clip text-ellipsis whitespace-nowrap">
          {props.event_name}
        </h1>
        <div className="text-[16px] mlg:text-[20px] xl:text-[22px] xxl:text-[27px] xxxl:text-[37px] flex items-center gap-[7px] text-text-color-gray">
          <span className="icons text-text-color-black">
            <ImLocation />
          </span>{" "}
          <p className="overflow-hidden text-ellipsis whitespace-nowrap">
            {props.location}
          </p>
        </div>
        <div className="text-[16px] mlg:text-[20px] xl:text-[22px] xxl:text-[27px] xxxl:text-[37px] flex items-center gap-[7px] text-text-color-gray">
          <span className="icons text-text-color-black">
            <SlCalender />
          </span>{" "}
          {event_date}
        </div>
        <div className="flex flex-wrap overflow-auto gap-2 w-[75%] ">
          {races}
        </div>
        <div className="text-[16px] mlg:text-[20px] xl:text-[22px] xxl:text-[27px] xxxl:text-[37px] absolute bottom-0 flex gap-2 items-baseline right-0  mr-[10px]">
          <span className="text-[20px] sm:text-[27px] mlg:text-[29px] xl:text-[31px] xxl:text-[36px] xxxl:text-[46px]  text-primary-color font-bold">
            {props.races.length}
          </span>{" "}
          Αγώνες
        </div>
      </div>
    </Link>
  );
}
