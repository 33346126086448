import React from "react";

export default function PrivacyPolicyPage() {
  return (
    <article className="bg-bg-color">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-8 ms:px-12 md:px-16 ml:px-20 lg:px-24 xl:px-28">
        <h1 className="text-primary-color text-Normal-big-headers-size font-bold leading-tight mb-4">
          Πολιτική Απορρήτου
        </h1>
        <hr className="border-primary-color h-px mb-8" />
        <section className="text-text-color-gray leading-loose text-Normal-mid-text-size opacity-70">
          <p className="mb-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id
            sagittis lorem. Donec in velit varius, commodo eros ac, feugiat
            lorem. Sed eget tincidunt odio. Vestibulum at sem nunc. Vestibulum
            ante ipsum primis in faucibus orci luctus et ultrices posuere
            cubilia Curae; Integer vel risus consequat, elementum magna ut,
            consectetur velit. Ut faucibus interdum elit, vitae euismod risus
            tincidunt at.
          </p>
          <p className="mb-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id
            sagittis lorem. Donec in velit varius, commodo eros ac, feugiat
            lorem. Sed eget tincidunt odio. Vestibulum at sem nunc. Vestibulum
            ante ipsum primis in faucibus orci luctus et ultrices posuere
            cubilia Curae; Integer vel risus consequat, elementum magna ut,
            consectetur velit. Ut faucibus interdum elit, vitae euismod risus
            tincidunt at.
          </p>
          <p className="mb-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id
            sagittis lorem. Donec in velit varius, commodo eros ac, feugiat
            lorem. Sed eget tincidunt odio. Vestibulum at sem nunc. Vestibulum
            ante ipsum primis in faucibus orci luctus et ultrices posuere
            cubilia Curae; Integer vel risus consequat, elementum magna ut,
            consectetur velit. Ut faucibus interdum elit, vitae euismod risus
            tincidunt at.
          </p>
          <p className="mb-4">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam id
            sagittis lorem. Donec in velit varius, commodo eros ac, feugiat
            lorem. Sed eget tincidunt odio. Vestibulum at sem nunc. Vestibulum
            ante ipsum primis in faucibus orci luctus et ultrices posuere
            cubilia Curae; Integer vel risus consequat, elementum magna ut,
            consectetur velit. Ut faucibus interdum elit, vitae euismod risus
            tincidunt at.
          </p>
          <p className="mb-4">
            Quisque euismod bibendum enim, id vestibulum nibh laoreet sit amet.
            Vestibulum convallis fermentum magna ac consequat. Sed viverra mi
            ipsum, eu blandit est volutpat vel. Nulla facilisi. Praesent feugiat
            mauris vitae aliquam feugiat. Ut lacinia tristique libero, sed
            consequat libero placerat vel.
          </p>
          <p className="mb-4">
            Proin nec orci ipsum. Sed luctus diam sed tortor hendrerit
            fringilla. Donec ac blandit lectus. Nulla viverra commodo eros quis
            varius. Aliquam ornare aliquet massa, eu commodo nulla laoreet sed.
            Vivamus interdum libero ac lacinia posuere.
          </p>
        </section>
      </div>
    </article>
  );
}
