import React, { useState } from "react";
import logo from "../../assets/Logo.png";
import Team from "../../data/Team/index";
import Members from "../../components/Members";
import Image from "../../assets/drone-marathon.webp";

export default function AboutUs() {
  const teamU = Team.map((member) => {
    if (member.id < 3) {
      return <Members key={member.id} {...member} />;
    }
  });
  const teamD = Team.map((member) => {
    if (member.id >= 3) {
      return <Members key={member.id} {...member} />;
    }
  });

  return (
    <article className="flex flex-col w-full bg-[#CDDDFF] ">
      <img
        src={logo}
        alt="rachunter logo"
        className="w-[30%] min-w-[350px] mt-10 ml:mt-[8rem] px-6 mx-[6rem] self-center"
      ></img>

      <div className="">
        <h1 className="text-center  text-primary-darker-color font-bold text-Normal-smallerHeaders-size md:w-full w-[50%] mx-auto md:text-Normal-subHeaders-size ml:text-Normal-headers-size my-7 md:my-[3rem] ">
          μια σελίδα ειδικά σχεδιασμένη για δρομείς
        </h1>

        <img
          src={Image}
          loading="lazy"
          alt="banner image"
          className="object-cover mx-auto rounded-[9px] w-[90%] h-[40vh] md:h-[60%] ml:h-[80%]"
        ></img>

        <section className="flex flex-col items-center mx-4 ml:mx-8 my-10 ml:my-[100px]">
          <h2 className="text-Normal-headers-size  md:text-Mid-big-headers-size ml:text-Normal-big-headers-size self-center text-center w-[95%] ml:w-[85%]  text-[#1552D6]">
            ‘‘Για εμάς είναι σημαντικό κάθε αθλητής-δρομέας να έχει την
            δυνατότητα για εύκολη, αλλά ολοκληρωμένη ενημέρωση!’’
          </h2>

          <p className="text-center self-center  md:self-end md:mr-[6rem] mt-[3rem]">
            Νικολαϊδης Ιωάννης, εμπνευστής της σελίδας, αλλά και δρομέας (17
            μαραθωνίων).
          </p>
        </section>

        <div className="h-fit bg-[#688BD6] py-3  ml:py-[3rem]">
          <h3 className=" text-Mid-big-headers-size  ml:text-[40px] text-center text-white font-extrabold py-10">
            Τι ξεχωρίζει την σελίδα μας;
          </h3>

          <section className="hidden ml:flex gap-[130px] px-[5rem]  text-white text-center">
            <ul
              className="flex flex-col gap-2 w-[60%]"
              style={{ listStyleType: "disc", textAlign: "left" }}
            >
              <li>
                <p>
                  Καλώς ήλθατε στο RaceHunter, στον ιστότοπο που έχει σχεδιαστεί
                  για να είναι η βασική σας πηγή για όλα όσα σχετίζονται με τους
                  αγώνες.
                </p>
              </li>
              <li>
                <p>
                  Γίνετε μέλος της κοινότητάς μας, δέστε τα παπούτσια σας και
                  αφήστε το RaceHunter να είναι ο οδηγός σας στο συναρπαστικό
                  ταξίδι σας στον μαραθώνιο.
                </p>
              </li>
            </ul>

            <ul
              className="flex flex-col gap-2"
              style={{ listStyleType: "disc", textAlign: "left" }}
            >
              <li>Παροχή πληροφοριών για όλους τους επερχόμενους αγώνες.</li>
              <li>Εξατομικευμένη αναζήτηση αγώνων με ποικιλία φίλτρων.</li>
              <li>
                Πολύτιμες πληροφορίες για την τοποθεσία, τις συνθήκες αλλά και
                του terrain του αγώνα.
              </li>
            </ul>
          </section>

          <section className="ml:hidden px-5 flex-col flex gap-8 ml:px-[5rem]  text-white">
            <p>
              Καλώς ήλθατε στο RaceHunter, στον ιστότοπο που έχει σχεδιαστεί για
              να είναι η βασική σας πηγή για όλα όσα σχετίζονται με τους αγώνες.
            </p>

            <ul
              className="flex flex-col gap-2"
              style={{ listStyleType: "disc", textAlign: "left" }}
            >
              <li>Παροχή πληροφοριών για όλους τους επερχόμενους αγώνες.</li>
              <li>Εξατομικευμένη αναζήτηση αγώνων με ποικιλία φίλτρων.</li>
              <li>
                Πολύτιμες πληροφορίες για την τοποθεσία, τις συνθήκες αλλά και
                του terrain του αγώνα.
              </li>
            </ul>

            <p>
              Γίνετε μέλος της κοινότητάς μας, δέστε τα παπούτσια σας και αφήστε
              το RaceHunter να είναι ο οδηγός σας στο συναρπαστικό ταξίδι σας
              στον μαραθώνιο.
            </p>
          </section>
        </div>

        <section className="px-6">
          <h1 className=" text-Normal-headers-size font-bold text-primary-darker-color text-center my-[4rem]">
            Γνώρισε την ομάδα μας!{" "}
          </h1>

          <div className="flex flex-col justify-center items-center gap-5 mb-5">
            <div className="flex flex-wrap gap-6 justify-center">{teamU}</div>
            <div className="flex flex-wrap gap-6 justify-center">{teamD}</div>
          </div>
        </section>
      </div>
    </article>
  );
}
