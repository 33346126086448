import React from "react";
import { Link } from "react-router-dom";
import { FaFacebook, FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";

export default function Footer() {
  return (
    <footer className="flex justify-between  mt-auto px-[18px] md:px-[35px] pt-[25px] pb-[15px] w-full bg-white">
      <section className="left-side w-full">
        <h1 className="font-semibold  text-Mid-big-headers-size md:text-Normal-big-headers-size ">
          RaceHunter
        </h1>
        <section className="md:hidden mt-[20px]">
          <h3 className="block mb-[10px] text-Mid-subHeaders-size text-text-color-gray ">
            Βρες μας στα Social Media!
          </h3>
          <ul className="flex space-x-[10px]">
            <li
              className="flex justify-center items-start  text-Mid-big-headers-size  text-[#4d4d4d] w-[45px] h-[45px] duration-[200ms] hover:cursor-pointer hover:text-[#4267b2] ease-in-out"
              id="icon-fb"
            >
              <a
                href="https://www.facebook.com/racehunter.gr"
                target="_blank"
                alt="RaceHunter Facebook"
              >
                <FaFacebook />
              </a>
            </li>
            <li
              className="flex justify-center items-start  text-Mid-big-headers-size  text-[#4d4d4d] w-[45px] h-[45px] duration-[200ms] hover:cursor-pointer hover:text-[#e95950] ease-in-out"
              id="icon-ig"
            >
              <a
                href="https://www.instagram.com/racehunter.gr"
                target="_blank"
                alt="RaceHunter Instagram"
              >
                <FaInstagram />
              </a>
            </li>
            <li
              className="flex justify-center items-start  text-Mid-big-headers-size  text-[#4d4d4d] w-[45px] h-[45px] duration-[200ms] hover:cursor-pointer hover:text-[#00acee] ease-in-out"
              id="icon-twitter"
            >
              <a
                href="https://twitter.com/RaceHunterGR"
                target="_blank"
                alt="RaceHunter Twitter"
              >
                <FaTwitter />
              </a>
            </li>
            <li
              className="flex justify-center items-start  text-Mid-big-headers-size  text-[#4d4d4d] w-[45px] h-[45px] duration-[200ms] hover:cursor-pointer hover:text-[#ff0000] ease-in-out"
              id="icon-yt"
            >
              <a
                href="https://www.youtube.com/@racehunterGR"
                target="_blank"
                alt="RaceHunter Youtube"
              >
                <FaYoutube />
              </a>
            </li>
          </ul>
        </section>

        <ul className="flex flex-col justify-evenly gap-1">
          <li className="leading-7">
            <Link
              className="text-[16px] lg:text-[18px] mlg:text-[20px] xl:text-[22px] xxl:text-[27px] xxxl:text-[35px] duration-[400ms] ease-in-out hover:underline hover:text-primary-color hover:cursor-pointer text-text-color-gray mb-[10px] w-fit "
              to="/about-us"
            >
              Σχετικά με εμάς
            </Link>
          </li>
          <li className="leading-7">
            <Link
              className="text-[16px] lg:text-[18px] mlg:text-[20px] xl:text-[22px] xxl:text-[27px] xxxl:text-[35px] duration-[400ms] ease-in-out hover:underline hover:text-primary-color hover:cursor-pointer text-text-color-gray mb-[10px] w-fit"
              to="/contact"
            >
              Επικοινωνία
            </Link>
          </li>
          <li className="leading-7">
            <Link
              className="text-[16px] lg:text-[18px] mlg:text-[20px] xl:text-[22px] xxl:text-[27px] xxxl:text-[35px] duration-[400ms] ease-in-out hover:underline hover:text-primary-color hover:cursor-pointer text-text-color-gray mb-[10px] w-fit"
              to="/terms-of-use"
            >
              Όροι Χρήσης
            </Link>
          </li>
          <li className="leading-7">
            <Link
              className="text-[16px] lg:text-[18px] mlg:text-[20px] xl:text-[22px] xxl:text-[27px] xxxl:text-[35px] duration-[400ms] ease-in-out hover:underline hover:text-primary-color hover:cursor-pointer text-text-color-gray mb-[10px] w-fit"
              to="/privacy-policy"
            >
              Πολιτική Απορρήτου
            </Link>
          </li>
        </ul>

        <div className="text-text-color-gray md:text-start  text-center md:text-text-color-black  text-[16px] lg:text-[18px] mlg:text-[20px] mt-2 xl:text-[22px] xxl:text-[27px] xxxl:text-[35px]">
          © 2023 RaceHunter. All rights reserved.
        </div>
      </section>

      <section className="md:block hidden mt-[50px] mb-[50px]">
        <h3 className="block mb-[20px] text-[16px] lg:text-[18px] mlg:text-[20px] xl:text-[22px] xxl:text-[27px] xxxl:text-[35px]">
          Βρες μας στα Social Media!
        </h3>
        <ul className="flex space-x-[40px]">
          <li
            className="flex justify-center items-center  text-Mid-big-headers-size md:text-Normal-big-headers-size text-[#4d4d4d] w-[45px] h-[45px] duration-[200ms] hover:cursor-pointer hover:text-[#4267b2] ease-in-out"
            id="icon-fb"
          >
            <a
              href="https://www.facebook.com/racehunter.gr"
              target="_blank"
              alt="RaceHunter Facebook"
            >
              <FaFacebook />
            </a>
          </li>
          <li
            className="flex justify-center items-center  text-Mid-big-headers-size md:text-Normal-big-headers-size text-[#4d4d4d] w-[45px] h-[45px] duration-[200ms] hover:cursor-pointer hover:text-[#e95950] ease-in-out"
            id="icon-ig"
          >
            <a
              href="https://www.instagram.com/racehunter.gr"
              target="_blank"
              alt="RaceHunter Instagram"
            >
              <FaInstagram />
            </a>
          </li>
          <li
            className="flex justify-center items-center  text-Mid-big-headers-size md:text-Normal-big-headers-size text-[#4d4d4d] w-[45px] h-[45px] duration-[200ms] hover:cursor-pointer hover:text-[#00acee] ease-in-out"
            id="icon-twitter"
          >
            <a
              href="https://twitter.com/RaceHunterGR"
              target="_blank"
              alt="RaceHunter Twitter"
            >
              <FaTwitter />
            </a>
          </li>
          <li
            className="flex justify-center items-center  text-Mid-big-headers-size md:text-Normal-big-headers-size text-[#4d4d4d] w-[45px] h-[45px] duration-[200ms] hover:cursor-pointer hover:text-[#ff0000] ease-in-out"
            id="icon-yt"
          >
            <a
              href="https://www.youtube.com/@racehunterGR"
              target="_blank"
              alt="RaceHunter Youtube"
            >
              <FaYoutube />
            </a>
          </li>
        </ul>
      </section>
    </footer>
  );
}
