import React, { useEffect, useState } from "react";
import { Link, Navigate, Route, Routes, useLocation } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import RacesPage from "./pages/RacesPage";
import ContactPage from "./pages/ContactPage";
import AboutUsPage from "./pages/AboutUsPage";
import RacesInformationPage from "./pages/RaceInformationPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import TermsOfUsePage from "./pages/TermsOfUsePage";
import ErrorPage from "./pages/ErrorPage";

export default function App() {
  const [hide, setHide] = useState(true);

  const [navSearch, setNavSearch] = useState("");

  const [clearSearch, setClear] = useState(false);

  const handleNavSearch = (aString) => {
    setNavSearch(aString);
  };

  const hideNav = (flag) => {
    setHide(flag);
  };

  const clear = () => {
    setClear(!clearSearch);
  };

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      setHide(true);
      const handleScroll = () => {
        const scrollY = window.scrollY;

        const scrollThreshold = 200;

        if (scrollY >= scrollThreshold) {
          setHide(false);
        } else {
          setHide(true);
        }
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        window.removeEventListener("scroll", handleScroll);
      };
    } else {
      setHide(false);
    }
  }, [location.pathname]);

  return (
    <div>
      <div
        className={
          hide ? "hidden" : "sticky top-[0] z-10 flex-col space-y-[-10px]"
        }
      >
        <Navbar func={handleNavSearch} clear={clearSearch} />
      </div>

      <Routes>
        <Route path="/" element={<Homepage func={handleNavSearch} />} />

        <Route path="/Error" element={<ErrorPage />} />

        <Route path="/contact" element={<ContactPage />} />

        <Route path="/about-us" element={<AboutUsPage />} />

        <Route path="/races">
          <Route
            index
            element={
              <RacesPage search={navSearch} func={hideNav} clearFunc={clear} />
            }
          />

          <Route path=":id" element={<RacesInformationPage />} />
        </Route>

        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />

        <Route path="/terms-of-use" element={<TermsOfUsePage />} />

        <Route path="*" element={<Navigate replace to="/Error" />} />
      </Routes>

      <Footer />
    </div>
  );
}
