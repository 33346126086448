import { useEffect } from "react";
import { useLocation } from "react-router";

// This a custom hook we use in order to navigate the user to the to of the screen

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
}
