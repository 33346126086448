import { Link } from "react-router-dom";
import React from "react";

export default function ErrorPage() {
  // just the error page
  return (
    <article
      id="error-page"
      className="bg-bg-color flex justify-center flex-col items-center gap-7 w-full py-[50px]"
    >
      <h1 className="text-Normal-headers-size">Υοπς, κάτι πήγε στραβά.</h1>
      <Link
        to="/"
        className="hover:bg-primary-color hover:text-white font-semibold px-2 py-2 border-solid border-[2px] border-primary-color rounded-[9px]"
      >
        Επιστροφή Στην Αρχική Οθόνη
      </Link>
    </article>
  );
}
