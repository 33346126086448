import React, { useState } from "react";
import { FaSortAmountDown } from "react-icons/fa";
import LocationCheckbox from "../../../../components/LocationCheckbox";
import { useApiData } from "../../../../data/Api";
import CustomCheckbox from "../../../../components/CustomCheckbox";

export default function GeneralFilters(props) {
  const { setUrl, url } = useApiData();

  //Marathon type flters [NearSearch,Sorting]

  const [nearMe, setNearMe] = useState(false);
  const [sort, setSort] = useState("newestFirst");

  function SortingHandler(event) {
    const { value } = event.target;
    setSort(value);

    if (value === "newestFirst") { // Default filtering is: ascending. That's why we only remove here.
      let updated_url = url.replace(`&sort=desc`, "");
      setUrl(updated_url);
    } else if (value === "oldestFirst") {
      if (url === "/events") {
        setUrl(`/events/filter?&sort=desc`);
      } else {
        setUrl((url) => url + `&sort=desc`);
      }
    }
  }

  function LocationHandler(lo, la, checked) {
    setNearMe(checked);

    if (checked === true) {
      if (url === "/events") {
        setUrl(`/events/filter?&ll=${lo},${la}`);
      } else {
        setUrl((url) => url + `&ll=${lo},${la}`);
      }
    } else if (checked === false) {
      let updated_url = url.replace(`&ll=${lo},${la}`, "");
      setUrl(updated_url)
    }
  }

  return (
    <div>
      <form className="filtersForm">
        <p className=" hidden ml:block text-black mt-[30px] text-[20px] mlg:text-[24px] xl:text-[26px] xxl:text-[31px] xxxl:text-[41px]">
          Φίλτρα Αναζήτησης
        </p>
        <div className=" text-text-color-gray  flex flex-col items-start mt-[30px] mb-[15px] gap-[16px] text-Normal-big-text-size ">
          <div>
            <LocationCheckbox
              label="Αγώνες κοντά μου"
              initiallyChecked={nearMe}
              onChange={LocationHandler}
              name="nearbyEnabled"
            />
          </div>

          <div className="hover:text-primary-color hover:cursor-pointer flex items-center gap-[10px]">
            <FaSortAmountDown className="min-w-[20px]" size={22} />
            <select
              className="bg-transparent  text-text-color-gray text-[16px] mlg:text-[20px] xl:text-[22px] xxl:text-[27px] xxxl:text-[35px] hover:text-primary-color hover:cursor-pointer flex items-center gap-[10px]"
              type=""
              id="sort"
              value={sort}
              onChange={SortingHandler}
              name="sorting"
            >
              <option value="newestFirst">Αγώνες Σύντομα</option>
              <option value="oldestFirst">Αγώνες Αργότερα</option>
            </select>
          </div>
        </div>
      </form>
    </div>
  );
}
