import React from "react";
import { useEffect } from "react";
import Tabs from "../../components/Tabs";
import logo from "../../assets/Logo.png";
import "../../assets/styles.css";
import { Link } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";
import img from "../../assets/homepageRun.png";
import { BiSearchAlt } from "react-icons/bi";
import { useApiData } from "../../data/Api";
import { useState } from "react";

export default function Homepage(props) {
  const { marathons, setUrl } = useApiData();

  // Nav Input
  const [query, setQuery] = useState("");

  // Hides the Search results that apears under the serach bar
  const [hideSerachResults, setHideSerachResults] = useState(false);

  useEffect(() => {
    function change() {
      props.func(true);
    }
    setUrl("/events");
    change();
  }, []);

  let numOfRaces = 0;

  function toggleSearchResults(flag) {
    setHideSerachResults(flag);
  }

  // it is the same as the nav bar
  function handleSearchRes(aString) {
    setQuery(aString);
    document.getElementById("searchBar").value = aString;
    document.getElementById("mobileSearchBar").value = aString;
  }

  function same(aString) {
    document.getElementById("searchBar").value = aString;
    document.getElementById("mobileSearchBar").value = aString;
  }

  const results = marathons.map((mar) => {
    if (
      mar.event_name.toUpperCase().includes(query.toUpperCase()) ||
      mar.location.toUpperCase().includes(query.toUpperCase())
    ) {
      numOfRaces++;
      return (
        <div
          key={mar.event_name}
          className="hover:cursor-pointer pb-3 space-y-1 relative"
        >
          <Link
            to={"/races"}
            state={{ search: mar.event_name, shouldSearch: true }}
            onClick={() => handleSearchRes(mar.event_name)}
          >
            <div className="hover:bg-light-primary-color hover:text-white p-[10px] w-[95%] duration-200 font-semibold rounded-[10px] ease-in-out">
              {mar.event_name}
            </div>
            <hr className="hover:bg-primary-darker-color w-[95%] bg-primary-color" />
          </Link>
        </div>
      );
    }
  });

  return (
    <article className="home-page">
      <section className="flex items-center flex-col justify-center m-auto">
        <nav className="m-12 flex flex-col justify-center items-center w-[95%] lg:w-[70%]">
          <div className="w-[100%] flex gap-[10px] md:gap-[20px] text-[#527FDF] font-bold mb-3">
            {/* <Link
              state={{ search: "", shouldSearch: true }}
              to="/races"
              className="hover:cursor-pointer bg-[#C3E6FC] p-2 rounded-[25px] hover:text-[white] hover:bg-[#043FBF] w-52 text-center shadow-md"
            >
              Μαραθώνιοι
            </Link> */}

            <Link
              state={{ search: "", shouldSearch: true }}
              to="/races"
              className="text-[11px] ms:text-[13px] md:text-[14px] lg:text-[16px] mlg:text-[18px] xl:text-[21px] xxl:text-[26px] xxxl:text-[36px] font-bold hover:cursor-pointer bg-[#C3E6FC] md:p-3 rounded-[25px] hover:text-[white] hover:bg-[#043FBF] w-[25%] flex justify-center items-center text-center shadow-md"
            >
              Αγώνες Δρόμου
            </Link>

            <button
              onClick={() =>
                document.getElementById("races").scrollIntoView({
                  behavior: "smooth",
                })
              }
              className="text-[11px] ms:text-[13px] md:text-[14px] lg:text-[16px] mlg:text-[18px] xl:text-[21px] xxl:text-[26px] xxxl:text-[36px] font-bold hover:cursor-pointer bg-[#C3E6FC] p-2 rounded-[25px] hover:text-[white] hover:bg-[#043FBF] w-[25%] flex justify-center items-center text-center shadow-md"
            >
              Αγώνες Σύντομα
            </button>

            <Link
              to="/contact"
              className="text-[11px] ms:text-[13px] md:text-[14px] lg:text-[16px] mlg:text-[18px] xl:text-[21px] xxl:text-[26px] xxxl:text-[36px] font-bold hover:cursor-pointer bg-[#C3E6FC] p-2 rounded-[25px] hover:text-[white] hover:bg-[#043FBF] w-[25%] flex justify-center items-center text-center shadow-md"
            >
              Επικοινωνία
            </Link>

            <Link
              to="/about-us"
              className="text-[11px] ms:text-[13px] md:text-[14px] lg:text-[16px] mlg:text-[18px] xl:text-[21px] xxl:text-[26px] xxxl:text-[36px] font-bold hover:cursor-pointer bg-[#C3E6FC] p-2 rounded-[25px] hover:text-[white] hover:bg-[#043FBF] w-[25%] flex justify-center items-center text-center shadow-md"
            >
              Σχετικά με μας
            </Link>
          </div>
          <hr className="w-[100%] bg-primary-color"></hr>
        </nav>
        <img src={logo} className="lg:w-[25%] w-[35%]" />
        <span className="text-primary-color text-[20px] mlg:text-[23px] xl:text-[27px] xxl:text-[32px] xxxl:text-[42px] font-bold">
          Όλα για τους αγώνες σε ένα σημείο!
        </span>
      </section>

      <section className="hidden md:flex items-center mt-6 justify-around">
        <img
          className="lg:w-[400px] w-[40%] mlg:w-[500px] xl:w-[550px] xxl:w-[700px] xxxl:w-[800px]"
          src={img}
        ></img>

        <div className="flex flex-col items-center gap-5">
          <div className="flex flex-col items-center gap-2">
            <div className="flex items-center rounded-[30px] bg-[#C3E6FC] px-5 hover:cursor-pointer">
              <Link
                state={{ search: query, shouldSearch: true }}
                to="/races"
                className=""
                id="searchbutton"
              >
                <button
                  className="flex justify-center text-primary-color"
                  onClick={() => {
                    handleSearchRes(query);
                    toggleSearchResults(true);
                  }}
                >
                  <BiSearchAlt size={25} />
                </button>
              </Link>

              <input
                id="searchBar"
                className={
                  hideSerachResults || query === ""
                    ? " text-black text-[16px] md:text-[18px] mlg:text-[18px] xl:text-[23px] xxl:text-[28px] xxxl:text-[38px] focus-visible:outline-none rounded-[30px] md:w-[250px] lg:w-[400px] mlg:w-[550px] xl:w-[600px] xxl:w-[700px] xxxl:w-[800px] rounded-l-none p-2 bg-[#C3E6FC]"
                    : "text-black text-[16px] md:text-[18px] mlg:text-[20px] xl:text-[23px] xxl:text-[28px] xxxl:text-[38px] focus-visible:outline-none rounded-[30px] md:w-[250] lg:w-[400px] mlg:w-[550px] xl:w-[600px] xxl:w-[700px] xxxl:w-[800px] p-2 bg-[#C3E6FC] "
                }
                type="search"
                placeholder="Αναζήτηση αγώνα ή τοποθεσίας"
                onChange={(e) => {
                  setQuery(e.target.value);
                  toggleSearchResults(false);
                  same(e.target.value);
                }}
                onFocus={() => toggleSearchResults(false)}
                onBlur={() => {
                  setTimeout(() => toggleSearchResults(true), 500);
                }}
                autoComplete="off"
              />
            </div>

            <div
              className={
                hideSerachResults || query === ""
                  ? `hidden`
                  : `text-[15px]  mlg:text-[21px] xl:text-[25px] xxl:text-[30px] xxxl:text-[40px] bg-[#C3E6FC] border-solid border-[2px] w-full rounded-[30px]  p-2 overflow-y-auto h-fit max-h-[150px]`
              }
            >
              {numOfRaces > 0 ? results : "Δεν βρέθηκαν αποτελέσματα!"}
            </div>
          </div>
          <Link
            to="/races"
            className="md:w-[40%] flex justify-center text-[13px] md:text-[14px] lg:text-[16px] mlg:text-[18px] xl:text-[21px] xxl:text-[26px] xxxl:text-[36px] font-bold bg-primary-darker-color text-white p-1 md:p-3 border-[2px] text-center rounded-[9px] border-primary-darker-color  border-solid"
            state={{ shouldSearch: false }}
          >
            Μάθε τι "τρέχει"
          </Link>

          {/* <button class="uiverse">
            <div class="wrapper">
              <span>Μάθε τι "τρέχει"</span>
              <div class="circle circle-12"></div>
              <div class="circle circle-11"></div>
              <div class="circle circle-10"></div>
              <div class="circle circle-9"></div>
              <div class="circle circle-8"></div>
              <div class="circle circle-7"></div>
              <div class="circle circle-6"></div>
              <div class="circle circle-5"></div>
              <div class="circle circle-4"></div>
              <div class="circle circle-3"></div>
              <div class="circle circle-2"></div>
              <div class="circle circle-1"></div>
            </div>
          </button> */}
        </div>
      </section>

      <section className="md:hidden flex flex-col items-center mt-10 gap-2 justify-evenly">
        <img className=" w-[50%]" src={img}></img>
        <div className="flex flex-col items-center gap-5 w-full">
          <div className="flex flex-col items-center gap-2 w-full">
            <div className="flex items-center w-[75%] rounded-[30px] bg-white px-2 hover:cursor-pointer">
              <Link
                state={{ search: query, shouldSearch: true }}
                to="/races"
                className=""
                id="searchbutton"
              >
                <button
                  className="flex justify-center text-primary-darker-color"
                  onClick={() => {
                    handleSearchRes(query);
                    toggleSearchResults(true);
                  }}
                >
                  <BiSearchAlt size={25} />
                </button>
              </Link>

              <input
                id="searchBar"
                className={
                  hideSerachResults || query === ""
                    ? "focus-visible:outline-none rounded-[30px] w-full rounded-l-none p-2"
                    : "focus-visible:outline-none rounded-[30px] w-full p-2"
                }
                type="search"
                placeholder="Αναζήτηση αγώνα ή τοποθεσίας"
                onChange={(e) => {
                  setQuery(e.target.value);
                  toggleSearchResults(false);
                  same(e.target.value);
                }}
                onFocus={() => toggleSearchResults(false)}
                onBlur={() => {
                  setTimeout(() => toggleSearchResults(true), 500);
                }}
                autoComplete="off"
              />
            </div>

            <div
              className={
                hideSerachResults || query === ""
                  ? `hidden`
                  : `bg-bg-color border-solid border-[2px] w-[75%] rounded-[30px]  p-2 overflow-y-auto h-fit max-h-[150px]`
              }
            >
              {numOfRaces > 0 ? results : "Δεν βρέθηκαν αποτελέσματα!"}
            </div>
          </div>
          <Link
            to="/races"
            className="md:w-[40%] flex justify-center text-Normal-text-size font-bold p-2 md:p-3 text-center text-white rounded-[9px] bg-primary-darker-color  "
            state={{ shouldSearch: false }}
          >
            Μάθε τι "τρέχει"
          </Link>
        </div>
      </section>

      <div id="races">
        <Tabs />
      </div>

      {/* <div className='py-[30px] flex flex-col'>
              <div className='flex flex-col m-auto w-[90%] '>
              <h1 className='text-Normal-headers-size font-semibold text-primary-color '>Nέα</h1>
              <div className='pt-10'>
                <Carousel responsive={responsive}>
                  <NewsCard/>
                  <NewsCard/>
                  <NewsCard/>
                  <NewsCard/>
                </Carousel>
              </div>
              </div>
              <div className='py-10 flex justify-center'>
                <button className=' px-10 py-2 text-center text-white bg-primary-color [400ms] border-solid border-[3px] border-primary-color rounded-[30px] 
              hover:bg-primary-darker-color hover:border-primary-darker-color hover:text-white'>Όλα τα νέα</button>
              </div>
              

            </div> */}

      {/* <div className="flex flex-col h-fit items-start gap-[3vh] bg-[#0D1317] py-[10vh] px-6 md:px-[12vh]">
              <h1 className="text-white md:text-Normal-big-headers-size text-Normal-headers-size font-bold">Ενημερώσου για όλους τους νέους αγώνες!</h1>
              <p className="text-[#FFB400] md:text-Normal-subHeaders-size   text-Normal-smallerHeaders-size">Συμπλήρωσε το email σου για να λαμβάνεις νέα και ενημερώσεις σχετικά με τους πιο πρόσφατους αγώνες!</p>

              <div className= " w-[100%] pt-[4vh] flex ml:w-[70%] items-center">
              
              <input 
                    className = "focus-visible:outline-none rounded-l-[30px] border-solid border-[2px] border-white text-Normal-smallerHeaders-size w-[65%] md:w-[55%]  py-[8px] px-[20px]"
                    type = "email" 
                    placeholder='email@example.com'
                    onChange={(e => setQuery(e.target.value))}/>

              <button className="rounded-r-[30px] w-[35%] md:w-[25%] text-Normal-smallerHeaders-size bg-[#FFB400] flex justify-center text-black font-bold px-[40px] py-[8px] border-[#FFB400] border-solid border-[2px] hover:bg-[#ffb300d0] hover:border-[#ffb300d0] ">Eγγράψου</button>
                
              </div>

            </div> */}
      {/* <Footer /> */}

      {/*<section className=" flex flex-col gap-[5vh] py-[5vh] px-[15vh] w-[100%]">
              <div className="flex items-stretch py-[7vh] ">
                 <span className="flex flex-col gap-[4vh] items-start py-[12vh] w-[100%]"><h1 className="text-primary-color text-Normal-big-headers-size font-[600] w-[100%]" >Όλες οι πληροφορίες για<br></br> τον αγώνα</h1>
                 <p className="w-[70%]  text-text-color-gray text-Normal-smallerHeaders-size">Όλες οι πληροφορίες που θα χρειαστείς οργανωμένες σε ένα σημείο για άμεση και εύκολη πρόσβαση</p>
                 </span>
                 <img style={{width:"60vh", height:"40vh" }} className="rounded-[5vh]" src={Feat1}  alt='image'/>
                 
                 
              </div>

              <div className="flex flex-row-reverse  items-stretch text-end py-[7vh]">
                
                <span className="flex flex-col gap-[4vh] items-end py-[12vh]  w-[100%]"><h1 className="text-primary-color text-Normal-big-headers-size font-[600] w-[100%]">Φίλτρα αναζήτησης</h1>
                <p className="w-[70%]  text-text-color-gray text-Normal-smallerHeaders-size">Βρες αγώνες με βάση κριτήρια, όπως η απόσταση, το κόστος συμμετοχής, ο τύπος της διαδρομής και πολλά άλλα</p>
                </span>
                <img style={{width:"60vh", height:"40vh"}} className="rounded-[5vh]" src={Feat3} alt='image'/>
              </div>

              <div className="flex items-stretch py-[7vh]">
               
               <span className="flex flex-col gap-[4vh] items-start py-[12vh] w-[100%]">
                  <h1 className="text-primary-color text-Normal-big-headers-size font-[600] w-[100%]">Μοναδικές προσφορές</h1>
                  <p className="w-[70%] text-text-color-gray text-Normal-smallerHeaders-size">Απόκτησε πρόσβαση σε πληθώρα εκπτώσεων και προσφορών για τη συμμετοχή σου σε αγώνες, την αγορά εξοπλισμού, την ενοικίαση καταλυμάτων κ.α.</p>
                </span>
                <img style={{width:"60vh", height:"40vh", alignSelf: 'center'}} className="rounded-[5vh]" src={Feat2} alt='image'/> 
              </div>
  </section>*/}
    </article>
  );
}
