import React, { useEffect } from "react";
// import * as ReactDOM from 'react-dom';
import GeneralFilters from "./Filters/GeneralFilters";
import DistanceFilters from "./Filters/DistanceFilters";
import AmenitiesFilters from "./Filters/AmenitiesFilters";
import { AiOutlineClose } from "react-icons/ai";
import { FaFilter } from "react-icons/fa";
import { useLocation } from "react-router";
import { useApiData } from "../../data/Api";
import { HiOutlineRefresh } from "react-icons/hi";
import RaceCard from "../RacesPage/RaceCard";

export default function MarathonsContainer(props) {
  const { marathons, url, setUrl } = useApiData();

  const location = useLocation();
  const search = location.state.search;
  const shouldSearch = location.state.shouldSearch;

  let numOfRaces = 0;

  useEffect(() => {
    setUrl("/events");
  }, []);

  const events = marathons.map((mar) => {
    if (shouldSearch === true) {
      if (
        mar.event_name.toUpperCase().includes(search.toUpperCase()) ||
        mar.location.toUpperCase().includes(search.toUpperCase())
      ) {
        numOfRaces++;
        return <RaceCard key={mar.event_id} {...mar} />;
      } else if (search === "") {
        numOfRaces++;
        return <RaceCard key={mar.event_id} {...mar} />;
      }
    } else {
      numOfRaces++;
      return <RaceCard key={mar.event_id} {...mar} />;
    }
  });

  function ToTOp() {
    window.scrollTo(0, 0);
  }

  const [filts, setFilts] = React.useState(false);

  const handleclick = () => {
    ToTOp();
    setFilts(!filts);
  };

  useEffect(() => {
    const handleresize = () => {
      if (window.innerWidth >= 980) {
        setFilts(false);
        props.func(false);
      }
    };
    window.addEventListener("resize", handleresize);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Clear the search query from localStorage
      localStorage.removeItem("search");
    };

    // Attach the beforeunload event
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <article className="bg-bg-color">
      <nav
        className={
          !filts
            ? "hidden"
            : "sticky top-[0] z-10 bg-primary-color text-white flex items-center text-Normal-headers-size gap-6 py-[15px] pl-[15px]"
        }
      >
        <AiOutlineClose
          size={30}
          onClick={() => {
            ToTOp();
            props.func(false);
            handleclick();
          }}
          className="hover:cursor-pointer"
        />
        Φίλτρα Αναζήτησης
      </nav>

      <div className="ml:flex lg:gap-[200px]">
        <section
          className={
            !filts
              ? "hidden ml:block pl-[40px] mt-[30px] w-[33%] min-w-[250px]"
              : "block m-auto px-[20px]"
          }
        >
          <GeneralFilters />
          <hr></hr>
          <DistanceFilters />
          <hr></hr>
          <AmenitiesFilters />
        </section>

        <section
          className={
            !filts ? "pt-[60px] w-full flex-col flex gap-4 " : "hidden"
          }
        >
          <div className="self-end mr-9 flex justify-end gap-2 items-baseline text-end">
            <span className="text-[25px] mlg:text-[29px] xl:text-[31px] xxl:text-[36px] xxxl:text-[46px] text-primary-color font-extrabold">
              {numOfRaces}
            </span>
            <span
              className={
                "text-text-color-gray font-semibold text-[18px] mlg:text-[22px] xl:text-[24px] xxl:text-[29px] xxxl:text-[39px] text-base ml-1"
              }
            >
              {numOfRaces === 1 ? "Αποτέλεσμα" : "Αποτελέσματα"}
            </span>
          </div>

          <div
            onClick={() => {
              props.clearFunc();
            }}
            className="self-end mr-9 flex justify-end gap-2 items-center text-end mb-[30px] text-[18px] mlg:text-[22px] xl:text-[24px] xxl:text-[29px] xxxl:text-[39px] w-fit hover:cursor-pointer hover:underline"
          >
            <span className="text-primary-color font-extrabold text-[25px] mlg:text-[29px] xl:text-[31px] xxl:text-[36px] xxxl:text-[46px]">
              <HiOutlineRefresh />
            </span>
            <span className="text-text-color-gray font-semibold text-base ml-1">
              Εκαθάριση Αναζήτησης
            </span>
          </div>

          <div className={numOfRaces > 0 ? "block" : "hidden"}>{events}</div>

          <div
            className={
              numOfRaces <= 0
                ? "block text-[20px] mlg:text-[24px] xl:text-[26px] xxl:text-[31px] xxxl:text-[41px]"
                : "hidden"
            }
          >
            Δεν βρέθηκαν Αποτέλεσματα.
          </div>
        </section>
      </div>

      <button
        className={
          filts
            ? "hidden"
            : "flex items-center gap-[5px] fixed bottom-[5%]  right-[5%] bg-primary-color text-white rounded-[30px] h-[47px] px-[20px] py-[12px] text-Mid-headers-size font-semibold ml:hidden"
        }
        onClick={() => {
          props.func(!filts);
          handleclick();
        }}
      >
        <FaFilter />
        Φιλτρα
      </button>
      <div
        className={
          !filts
            ? "hidden"
            : "flex justify-evenly text-Normal-subHeaders-size py-[30px]"
        }
      >
        <button
          className="bg-white text-primary-color border-[1px] border-solid border-primary-color rounded-[9px] px-[23px] py-[7px] cursor-pointer"
          onClick={() => {
            window.location.reload(false);
          }}
        >
          Εκαθάριση
        </button>
        <button
          className="bg-primary-color text-white border-[1px] border-solid border-primary-color rounded-[9px] px-[23px] py-[7px] cursor-pointer"
          onClick={() => {
            props.func(!filts);
            handleclick();
          }}
        >
          Εφαρμογή
        </button>
      </div>
    </article>
  );
}
