import React, { useState } from "react";
import { HiMail, HiLocationMarker } from "react-icons/hi";

export default function ContactPage() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    content: "",
  });

  const [formCheck, setFormCheck] = useState({
    name: false,
    email: false,
    subject: false,
    content: false,
  });

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    setFormCheck((prevFormCheck) => ({
      ...prevFormCheck,
      [name]: false,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.name === "") {
      setFormCheck((prevFormCheck) => ({ ...prevFormCheck, name: true }));
    }
    if (formData.email === "") {
      setFormCheck((prevFormCheck) => ({ ...prevFormCheck, email: true }));
    }
    if (formData.subject === "") {
      setFormCheck((prevFormCheck) => ({ ...prevFormCheck, subject: true }));
    }
    if (formData.content === "") {
      setFormCheck((prevFormCheck) => ({ ...prevFormCheck, content: true }));
    }

    if (
      formData.name !== "" &&
      formData.email !== "" &&
      formData.subject !== "" &&
      formData.content !== ""
    ) {
      fetch(`${process.env.REACT_APP_API_URL}/contact/send`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      })
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network error");
          }
          setShowSuccessPopup(true);
          setTimeout(() => {
            setShowSuccessPopup(false);
          }, 4500);
        })
        .catch((error) => {
          console.error("Error sending form data:", error);
        });

      setFormData({
        name: "",
        email: "",
        subject: "",
        content: "",
      });
    }
  };

  return (
    <article className="relative">
      <div className="ml:bg-primary-color px-3 ml:px-10 ml:py-10">
        <h1 className="font-bold mx-10 ml:text-Normal-big-headers-size mlg:text-[60px] xl:text-[65px] xxl:text-[70px] xxxl:text-[80px] ml:text-bg-color text-primary-color ml:mb-4 ml:ml-12 text-Normal-headers-size">
          Επικοινωνήστε μαζί μας
        </h1>
        <hr className="bg-primary-color mx-10 ml:hidden px-6" />
        <div className="ml:items-center ml:flex ml:justify-between ml:rounded-3xl ml:bg-bg-color ml:py-10 ml:p-10 mx-10 my-10">
          <section className="flex flex-col w-full ml:w-[60%] text-[18px] lg:text-[20px] mlg:text-[25px] xl:text-[29px] xxl:text-[30px] xxxl:text-[35px]">
            <input
              className={
                formCheck.name
                  ? "rounded-lg px-6 py-4 bg-blue-100 mb-3 border-solid border-[2px] border-red-600"
                  : "rounded-lg px-6 py-4 bg-blue-100 mb-3"
              }
              type="text"
              name="name"
              placeholder="Όνομα"
              value={formData.name}
              onChange={handleChange}
            />
            <input
              className={
                formCheck.email
                  ? "rounded-lg px-6 py-4 bg-blue-100 mb-3 border-solid border-[2px] border-red-600"
                  : "rounded-lg px-6 py-4 bg-blue-100 mb-3"
              }
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
            />
            <input
              className={
                formCheck.subject
                  ? "rounded-lg px-6 py-4 bg-blue-100 mb-3 border-solid border-[2px] border-red-600"
                  : "rounded-lg px-6 py-4 bg-blue-100 mb-3"
              }
              type="text"
              name="subject"
              placeholder="Θέμα"
              value={formData.subject}
              onChange={handleChange}
            />
            <textarea
              className={
                formCheck.content
                  ? "rounded-lg px-6 py-4 bg-blue-100 mb-3 h-24 resize-none border-solid border-[2px] border-red-600"
                  : "rounded-lg px-6 py-4 bg-blue-100 mb-3 h-24 resize-none"
              }
              name="content"
              placeholder="Το μήνυμά σας..."
              value={formData.content}
              onChange={handleChange}
            />
            <input
              onClick={handleSubmit}
              id="send"
              className="rounded-lg px-6 py-4 bg-primary-color text-white font-bold hover:bg-blue-700 cursor-pointer"
              type="submit"
              value="Αποστολή"
            />
          </section>
          <section className="flex flex-col ml:w-[30%] ml:gap-6 ml:mt-0 mt-3 gap-3 text-[14px] lg:text-[18px] mlg:text-[20px] xl:text-[22px] xxl:text-[24px] xxxl:text-[28px]">
            <div className="flex">
              <HiLocationMarker className="text-Mid-big-headers-size ml:text-Normal-big-headers-size px-1 py-1 border-2 border-primary-color rounded-full text-primary-color mr-4 self-center" />
              <h2 className="text-text-color-gray ">
                Eγνατία 156, 546 36,
                <br />
                Θεσσαλονίκη, Ελλάδα
              </h2>
            </div>
            <div className="flex items-center">
              <HiMail className="ml:text-Normal-big-headers-size text-Mid-big-headers-size py-1 px-1 border-2 border-primary-color rounded-full text-primary-color mr-4 self-center" />
              <h2 className="text-text-color-gray">racehunterservice@gmail.com</h2>
            </div>
          </section>
        </div>
      </div>

      {showSuccessPopup && (
        <div className="text-[14px] lg:text-[18px] mlg:text-[20px] xl:text-[22px] xxl:text-[24px] xxxl:text-[28px] absolute top-[20px] w-full animate-bounce right-3 ease-in-out duration-900 ml:flex hidden items-center justify-end">
          <div className="bg-green-500 font-bold text-white py-4 px-8 rounded-md">
            Το email στάλθηκε επιτυχώς!
          </div>
        </div>
      )}

      {showSuccessPopup && (
        <div className="text-[14px] lg:text-[18px] mlg:text-[20px] xl:text-[22px] xxl:text-[24px] xxxl:text-[28px] fixed bottom-2 w-full animate-bounce ease-in-out duration-900 flex ml:hidden items-center justify-center">
          <div className="bg-green-500 font-bold text-white py-4 px-8 rounded-md">
            Το email στάλθηκε επιτυχώς!
          </div>
        </div>
      )}
    </article>
  );
}
