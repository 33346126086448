import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import axios from "axios";

const ApiDataContext = createContext();

export function useApiData() {
  return useContext(ApiDataContext);
}

export function Api({ children }) {
  const [marathons, setMarathons] = useState([]);
  const [url, setUrl] = useState("");

  // Fetch data when the URL changes
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}${url}`,
          {
            headers: {
              "ngrok-skip-browser-warning": true,
            },
          }
        );
        const res = response.data.events;
        setMarathons(res);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchData();
  }, [url]);

  const apiData = useMemo(() => {
    return { marathons, setUrl, url };
  }, [marathons, setUrl, url]);

  return (
    <ApiDataContext.Provider value={apiData}>
      {children}
    </ApiDataContext.Provider>
  );
}
