import { useState } from "react";
import { useApiData } from "../../data/Api";
import RaceCard from "../../pages/RacesPage/RaceCard";

function Tabs() {
  const { marathons } = useApiData();

  // Get the 3 sooner events
  let j = 0;
  const events_soon = marathons.map((mar) => {
    if (j < 3) {
      j++;
      return <RaceCard key={mar.event_id} {...mar} homepage={true} />;
    }
  });

  return (
    <div className="w-[100%] h-fit flex flex-col relative md:mt-[70px]">
      <div className="flex justify-center mt-7 w-[100%] text-primary-color font-semibold text-[17px] md:text-[17px] lg:text-[21px] mlg:text-[23px] xl:text-[25px] xxl:text-[30px] xxxl:text-[35px]">
        <span className="text-white tabs block border-x-0 border-t-0 border-y-4 border-[3px] border-b-[white]">
          Πρόσφατα
        </span>
      </div>

      <div className="w-[100%] h-fit">
        <div className="w-[100%] ml:w-[70%] m-auto py-6">
          <h1 className=" text-center text-white  "> </h1>
          <div>{events_soon}</div>
        </div>
      </div>
    </div>
  );
}

export default Tabs;
